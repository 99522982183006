<template>
  <div>
    <Modal :options="{width: '50vw', close: true, esc:false}" @close="$emit('close')">
      <div class="title">Editar campo</div>
      <div class="body">
        <div class="row form-group">
          <label for="campo.nombre" class="col-form-label col-sm-3">Nombre</label>
          <div class="col-sm-9"><input v-model="campo_edit.nombre" class="form-control" type="text" name="campo.nombre" id="campo.nombre" /></div>
        </div>
        <div class="row form-group">
          <label for="campo.tipo" class="col-form-label col-sm-3">Tipo</label>
          <div class="col-sm-9">
            <select v-model="campo_edit.tipo" name="campo.tipo" id="campo.tipo" class="form-control">
              <option value="text">Texto</option>
              <option value="textarea">Texto grande</option>
              <option value="files">Documentos</option>
              <option value="select">Selección</option>
              <option value="file">Documento</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="campo.valor" class="col-form-label col-sm-3">Valor</label>
          <div class="col-sm-9"><input v-model="campo_edit.valor" class="form-control" type="text" name="campo.valor" id="campo.valor" /></div>
        </div>
        <div class="row form-group">
          <label for="campo.valor_tipo" class="col-form-label col-sm-3">Valor tipo</label>
          <div class="col-sm-9">
            <select v-model="campo_edit.valor_tipo" name="campo.valor_tipo" id="campo.valor_tipo" class="form-control">
              <option value="string">String</option>
              <option value="numeric">Numeric</option>
              <option value="boolean">Boolean</option>
              <option value="json">Json</option>
              <option value="array">Array</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="campo.requerido" class="col-form-label col-sm-3">Requerido</label>
          <div class="col-sm-9"><BpBSwitch v-model="campo_edit.requerido" texton="Sí" textoff="No" name="campo.requerido" id="campo.requerido" value="1" /></div>
        </div>
        <div v-if="campo_edit.tipo == 'files'" class="row form-group">
          <label for="campo.documentos_maximos" class="col-form-label col-sm-3">Documentos a cargar</label>
          <div class="col-sm-9"><input v-model="campo_edit.documentos_maximos" class="form-control" name="campo.documentos_maximos" id="campo.documentos_maximos" type="number" /></div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="guardar_cambios">Guardar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar edición</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/Modal';
  import BpBSwitch from '@/components/Form/Switch';

  export default {
    components: {
      Modal, BpBSwitch
    }
    ,props: {
      campo: {
        type: Object,
        default() {
          return {
            id: null,
            nombre: null,
            tipo: 'text',
            valor: null,
            valor_tipo: 'string',
            requerido: 1,
            documentos_requeridos: 1,
            documentos_maximos: 1
          }
        }
      }
    }
    ,data() {
      return {
        campo_edit: {
          id: null,
          nombre: null,
          tipo: 'text',
          valor: null,
          valor_tipo: 'text',
          requerido: 1,
          documentos_requeridos: 1,
          documentos_maximos: 1
        }
      }
    }
    ,mounted() {
      this.campo_edit = this.$helper.clone(this.campo);
    }
    ,methods: {
      guardar_cambios() {
        this.campo_edit.documentos_requeridos = this.campo_edit.tipo == 'files';
        this.campo_edit.documentos_maximos = this.campo_edit.documentos_requeridos ? this.campo_edit.documentos_maximos : 0;

        this.$emit('update',this.campo_edit);
      }
    }
  }
</script>